import React, { useContext } from 'react';
import {
  Card,
  Text,
  Shelf,
  Box,
  Stack,
  Button,
  Heading,
  Tooltip,
  useOnMount,
} from 'chaser-components';
import { PaymentContext } from '../../../../../providers/paymentContext';
import pluralize from 'pluralize';
import { toCurrency } from '../../../../../util';
import useSelectedTotal from '../../../../../hooks/useSelectedTotal';
import useCardDetails from '../../../../../hooks/useCardDetails';
import useBankDetails from '../../../../../hooks/useBankDetails';
import useDirectDebit from '../../../../../hooks/useDirectDebit';
import useCreditor from '../../../../../hooks/useCreditor.js';
import useRates from '../../../../../hooks/useRates.js';
import {
  ddMandateMapping,
  payment,
  paymentProviders,
  paymentTypes,
} from '../../../../../util/constants';
import DirectDebitOverviewModal from '../../DirectDebitOverviewModal/index.jsx';
import useTotalWithOrWithoutFees, {
  summaryFeesInfo,
} from '../../../../../hooks/useTotalWithOrWithoutFees.js';

const Summary = () => {
  const {
    setPaymentSuccess,
    pay,
    setShowModalDirectDebitOverview,
    paymentOptions: { paymentResult },
  } = useContext(PaymentContext);
  const { amount: selectedTotal, currency, numberOfInvoices } = useSelectedTotal();
  const cardDetails = useCardDetails(paymentProviders.stripe);
  const bankDetails = useBankDetails();
  const hasBankDetails = Array.isArray(bankDetails) && bankDetails.length > 0;
  const directDebit = useDirectDebit();
  const { baseCurrency } = useCreditor();
  const rates = useRates();

  useOnMount(() => {
    if (paymentResult?.success) {
      setPaymentSuccess();
    }
  });

  const totalWithoutFeesForCards = summaryFeesInfo(
    cardDetails,
    paymentTypes.card,
    baseCurrency,
    currency,
    selectedTotal,
    rates,
  );
  const totalWithFeesForCards = useTotalWithOrWithoutFees(
    paymentTypes.card,
    selectedTotal,
    baseCurrency,
    currency,
  );
  const totalWithFeesForDebit = useTotalWithOrWithoutFees(
    ddMandateMapping[currency.toLowerCase()] &&
      ddMandateMapping[currency.toLowerCase()].stripeDDMethod,
    selectedTotal,
    baseCurrency,
    currency,
  );
  const ddTooltipText = `Processing fees ${
    totalWithFeesForCards > totalWithFeesForDebit
      ? totalWithFeesForCards > totalWithFeesForDebit
        ? 'are lower'
        : 'are higher'
      : ''
  } for payments made via Direct Debit`;

  return (
    <>
      <DirectDebitOverviewModal currency={currency} />
      <Card>
        <Box p="medium">
          <Stack dividers>
            <Stack>
              <Text fontSize="medium" fontWeight="bold">
                Summary
              </Text>
              <Stack gap="xsmall">
                <Shelf justifyContent="space-between">
                  <Text color="Mid Neutral 1">{pluralize('invoices', numberOfInvoices, true)}</Text>
                  <Text color="Mid Neutral 1">{toCurrency(selectedTotal, currency)}</Text>
                </Shelf>
                {totalWithFeesForCards !== selectedTotal && (
                  <Shelf justifyContent="space-between">
                    <Text color="Mid Neutral 1">{'Processing fee'}</Text>
                    <Text color="Mid Neutral 1">
                      {toCurrency(
                        totalWithoutFeesForCards?.fixedFee +
                          totalWithoutFeesForCards?.amountOfvariablePercentageFee,
                        currency,
                      )}
                    </Text>
                  </Shelf>
                )}
              </Stack>
            </Stack>
            <Stack gap="small">
              <Stack gap="xsmall">
                <Shelf justifyContent="space-between">
                  <Text>Total</Text>
                  <Heading data-testid="summary-total" fontWeight="600">
                    {toCurrency(totalWithFeesForCards, currency)}
                  </Heading>
                </Shelf>
                {totalWithFeesForDebit !== selectedTotal && (
                  <Shelf justifyContent="space-between">
                    <Text></Text>
                    <Tooltip underline content={ddTooltipText} position="right">
                      {toCurrency(totalWithFeesForDebit, currency)}
                    </Tooltip>
                  </Shelf>
                )}
              </Stack>
              <Stack gap="xsmall" alignItems="stretch" display="flex" flexDirection="column">
                <Tooltip
                  as="span"
                  disable={!cardDetails?.isMultiCurrency}
                  position="left"
                  content="Multi-currency payments are not supported"
                >
                  {cardDetails?.cardPaymentActive && (
                    <Button
                      flexGrow="1"
                      as="span"
                      disabled={numberOfInvoices === 0 || cardDetails?.isMultiCurrency}
                      onClick={() => {
                        pay({
                          paymentType: payment.card,
                          provider: paymentProviders.stripe,
                        });
                      }}
                      display="flex"
                      data-testid="pay-card-btn"
                    >
                      Pay now
                    </Button>
                  )}
                </Tooltip>
                {directDebit?.showDirectDebitActions && (
                  <Tooltip
                    as="span"
                    disable={!directDebit.hasDirectDebitActive}
                    position="left"
                    content="To make any changes to an active direct debit, please contact the bank directly."
                  >
                    <Button
                      flexGrow="1"
                      as="span"
                      disabled={!directDebit.canActionDirectDebit}
                      onClick={() => {
                        setShowModalDirectDebitOverview(true);
                      }}
                      display="flex"
                      appearance={'secondary'}
                      data-testid="pay-card-btn"
                    >
                      {directDebit.hasDirectDebitActive
                        ? 'Cancel Direct Debit'
                        : 'Pay and set up Direct Debit'}
                    </Button>
                  </Tooltip>
                )}
                {hasBankDetails && (
                  <Button
                    as="span"
                    disabled={selectedTotal === 0}
                    onClick={() => {
                      pay({
                        provider: paymentProviders.stripe,
                        paymentType: payment.bank,
                      });
                    }}
                    display="flex"
                    appearance={cardDetails ? 'secondary' : 'primary'}
                    data-testid="pay-transfer-btn"
                  >
                    See bank transfer information
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Card>
    </>
  );
};

export default Summary;
