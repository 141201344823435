const payment = Object.freeze({
  card: 'card',
  debit: 'debit',
  bank: 'bank',
});

const paymentProviders = Object.freeze({
  unipaas: 'UNIPAAS',
  stripe: 'STRIPE',
});

const multiOptionalTypeProviders = [paymentProviders.stripe];

const unipaasOnboardingStatus = ['COMPLETED'];

const unipaasSupportedCurrencies = ['EUR', 'USD', 'GBP'];

const accountIntegrationSystemsSupported = ['XERO', 'QUICKBOOKS'];

const unipaasAuthorizationStatus = Object.freeze({
  captured: 'Captured',
});

const debitCurrencyMapping = Object.freeze({
  eur: 'sepa_debit',
  aud: 'au_becs_debit',
  gbp: 'bacs_debit',
  usd: 'us_bank_account',
});

const paymentTypes = Object.freeze({
  card: 'card',
  bank: 'bank',
  bacs_debit: 'bacs_debit',
  becs_debit: 'becs_debit',
  sepa_debit: 'sepa_debit',
  au_becs_debit: 'au_becs_debit',
  us_bank_account: 'us_bank_account',
});

const ddMandateMapping = {
  gbp: {
    contactDDMandatePropertyAt: 'firstTimeAcceptedBacsAt',
    stripeDDMethod: 'bacs_debit',
    name: 'BACS',
  },
  eur: {
    contactDDMandatePropertyAt: 'firstTimeAcceptedSepaAt',
    stripeDDMethod: 'sepa_debit',
    name: 'SEPA',
  },
  aud: {
    contactDDMandatePropertyAt: 'firstTimeAcceptedBecsAt',
    stripeDDMethod: 'au_becs_debit',
    name: 'BECS',
  },
  usd: {
    contactDDMandatePropertyAt: 'firstTimeAcceptedAchAt',
    stripeDDMethod: 'us_bank_account',
    name: 'ACH',
  },
};

const integrations = Object.freeze({
  ACCOUNTSIQ: 'accountsiq',
  CSV: 'csv',
  SAGE: 'sage',
  FREEAGENT: 'freeagent',
  QUICKBOOKS: 'quickbooks',
  DYNAMICS: 'dynamics365businesscentral',
  SAGECLOUD: 'sage200cloud',
  FRESHBOOKS: 'freshbooks',
  MYOB: 'myobaccountright',
  XERO: 'xero',
  ZOHOBOOKS: 'zohobooks',
  OPENAPI: 'openapi',
  ZAPIER: 'zapier',
  D365BC: 'd365bc',
  NETSUITE: 'netsuite',
});

export {
  payment,
  debitCurrencyMapping,
  paymentTypes,
  multiOptionalTypeProviders,
  ddMandateMapping,
  integrations,
  paymentProviders,
  unipaasOnboardingStatus,
  unipaasSupportedCurrencies,
  unipaasAuthorizationStatus,
  accountIntegrationSystemsSupported,
};
