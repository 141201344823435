import { every } from 'lodash';
import useCreditor from './useCreditor';
import useSelectedTotal from './useSelectedTotal';

function useBankDetails() {
  const { payments, BaseCurrency } = useCreditor();
  const { numberOfInvoices, invoices } = useSelectedTotal();
  const bankDetails = payments?.bankDetails;

  if (numberOfInvoices === 0) return false;
  if (!bankDetails) return false;

  const currencies = [...new Set([...invoices.map(i => i.CurrencyCode)]), BaseCurrency];

  const accounts =
    Array.isArray(bankDetails) &&
    bankDetails.filter(
      ({ fields, currency }) =>
        currencies.includes(currency) &&
        Array.isArray(fields) &&
        fields.length > 0 &&
        every(fields, f => f.fieldName && f.value),
    );

  if (accounts) {
    accounts.sort(a => (a.currency === BaseCurrency ? 1 : -1));
  }

  return accounts;
}

export default useBankDetails;
