import React from 'react';
import { Button } from 'chaser-components';
import { string } from 'prop-types';
import useContact from '../../../hooks/useContact';
import { integrations } from '../../../util/constants';
import config from '../../../config';

function isDownloadEnabled(accountingSoftware, hasInvoicePdfLink) {
  const requiresInvoicePdfLink = [integrations.ZAPIER, integrations.OPENAPI, integrations.CSV];

  if (requiresInvoicePdfLink.includes(accountingSoftware) && !hasInvoicePdfLink) {
    return false;
  }

  return (
    accountingSoftware === integrations.XERO ||
    accountingSoftware === integrations.DYNAMICS ||
    accountingSoftware === integrations.FREEAGENT ||
    accountingSoftware === integrations.QUICKBOOKS ||
    accountingSoftware === integrations.SAGE ||
    accountingSoftware === integrations.ACCOUNTSIQ ||
    accountingSoftware === integrations.FRESHBOOKS ||
    accountingSoftware === integrations.D365BC ||
    accountingSoftware === integrations.NETSUITE ||
    accountingSoftware === integrations.OPENAPI ||
    accountingSoftware === integrations.ZAPIER ||
    accountingSoftware === integrations.CSV
  );
}

const InvoicePDF = ({ invoiceId, invoicePdfLink, accountingSoftware }) => {
  const { orgId, contactId } = useContact();

  const canDownlod = isDownloadEnabled(accountingSoftware, !!invoicePdfLink);
  if (!canDownlod) return null;

  return (
    <Button
      style={{ padding: '5px' }}
      as="a"
      target="_blank"
      size="narrow"
      href={`${config.apiUrl}/c/${orgId}/d/${contactId}/i/${invoiceId}/pdf`}
      appearance="secondary"
      icon="download"
    />
  );
};

InvoicePDF.propTypes = {
  invoiceId: string.isRequired,
  invoicePdfLink: string,
  accountingSoftware: string,
};

export default InvoicePDF;
