import { useQuery } from 'react-query';
import useSelectedTotal from './useSelectedTotal';
import ApiService from '../services/api';
import useContact from './useContact';
import useProvider from './useProvider';
import {
  debitCurrencyMapping,
  ddMandateMapping,
  multiOptionalTypeProviders,
  accountIntegrationSystemsSupported,
} from '../util/constants';
import useDebtor from './useDebtor';
import useCreditor from './useCreditor';
const { find } = require('lodash');

const getDebtorPaymentInformation = async (orgId, debtorId) => {
  const { data } = await ApiService().get(`/c/${orgId}/d/${debtorId}/paymentinformation`);
  return data;
};

function useDirectDebit() {
  const { orgId, contactId } = useContact();
  const { accountingSoftware } = useCreditor();
  const { directDebit } = useDebtor();
  const { invoices, currency, numberOfInvoices } = useSelectedTotal();
  const paymentProvider = useProvider('STRIPE');
  const { data: customerPaymentInformation, isLoading } = useQuery(
    [orgId, contactId],
    getDebtorPaymentInformation,
    {
      enabled: contactId && paymentProvider,
      suspense: false,
    },
  );

  const validPaymentCurrencies =
    paymentProvider &&
    paymentProvider.currencies &&
    paymentProvider.currencies.filter(
      c =>
        currency.toLowerCase() === c.currency.toLowerCase() &&
        c.type === debitCurrencyMapping[currency.toLowerCase()],
    );

  const isValidPaymentCurrencies =
    (validPaymentCurrencies && validPaymentCurrencies.length > 0) ||
    !accountIntegrationSystemsSupported.includes(accountingSoftware);

  const ddAggreementField = ddMandateMapping[currency.toLowerCase()]?.contactDDMandatePropertyAt;
  const hasDirectDebitActive = !!(!!directDebit && directDebit[ddAggreementField]);

  const isValid =
    numberOfInvoices === 0 && hasDirectDebitActive ? true : numberOfInvoices > 0 ? true : false;

  const isEnabledType =
    multiOptionalTypeProviders.includes(paymentProvider?.provider?.toUpperCase()) &&
    paymentProvider.enabledTypes?.includes(debitCurrencyMapping[currency.toLowerCase()]);

  if (!isEnabledType) {
    return {
      hasDirectDebitActive,
      canActionDirectDebit: false,
      showDirectDebitActions: false,
      isLoading: false,
    };
  }

  const showDirectDebitActions = isValid && isValidPaymentCurrencies && isEnabledType;

  if (!isValidPaymentCurrencies) {
    return {
      hasDirectDebitActive,
      canActionDirectDebit: false,
      showDirectDebitActions,
      isLoading: false,
    };
  }

  if (isLoading) {
    return {
      hasDirectDebitActive,
      canActionDirectDebit: false,
      showDirectDebitActions,
      isLoading,
    };
  }

  const currencies = [...new Set(invoices.map(p => p.CurrencyCode.toLowerCase()))];
  const isMultiCurrency = currencies.length > 1;

  if (isMultiCurrency) {
    return {
      hasDirectDebitActive,
      canActionDirectDebit: false,
      showDirectDebitActions: false,
      isLoading,
    };
  }

  const isSupportedCurrency = Object.keys(debitCurrencyMapping).includes(currency.toLowerCase());

  if (isSupportedCurrency) {
    if (directDebit && customerPaymentInformation.paymentMethods?.length === 0) {
      return {
        hasDirectDebitActive,
        canActionDirectDebit: !hasDirectDebitActive,
        showDirectDebitActions,
        isLoading,
      };
    }

    const paymentMethodFound =
      find(customerPaymentInformation.paymentMethods, {
        type: debitCurrencyMapping[currency.toLowerCase()],
      }) ?? false;

    return {
      hasDirectDebitActive,
      canActionDirectDebit: !paymentMethodFound,
      showDirectDebitActions,
      isLoading,
    };
  }

  return {
    hasDirectDebitActive,
    canActionDirectDebit: false,
    showDirectDebitActions,
    isLoading,
  };
}

export default useDirectDebit;
