import {
  Button,
  Icon,
  Shelf,
  Stack,
  Text,
  useOnOutsideClick,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  TabList,
  Select,
  Token,
  Tooltip,
  Position,
} from 'chaser-components';
import React, { useContext, useRef, useState } from 'react';
import { bool, number, oneOfType, string, node } from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PaymentContext } from '../../../../../../providers/paymentContext';
import { ellipsis } from '../../../../../../util/index';
import style from './Details.module.scss';
import useBankDetails from '../../../../../../hooks/useBankDetails';
import pluralize from 'pluralize';
import useSelectedTotal from '../../../../../../hooks/useSelectedTotal';

const CopyRow = ({ title, text, data, truncate = false, children }) => {
  const copyRef = useRef();
  const [copied, setCopied] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useOnOutsideClick(copyRef, () => {
    setCopied(false);
  });

  return (
    <Shelf justifyContent="space-between">
      <Stack gap="small">
        <Text as="div" color="Mid Neutral 1">
          {title}
        </Text>
        {text && (
          <Text fontWeight="bold" as="span">
            {truncate ? ellipsis(text, 30) : text}
          </Text>
        )}
        {children}
      </Stack>
      <Shelf gap="xsmall">
        {isHovering && !copied && <Text color="Mid Neutral 1">Copy</Text>}
        {copied && (
          <Text className={style.copied} color="Mid Neutral 1">
            Copied!
          </Text>
        )}
        <CopyToClipboard
          text={data || text}
          onCopy={() => {
            setCopied(true);
          }}
        >
          <Icon
            className={style.icon}
            size={19}
            name="copy"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          />
        </CopyToClipboard>
      </Shelf>
    </Shelf>
  );
};

CopyRow.propTypes = {
  title: string,
  text: oneOfType([string, number]),
  truncate: bool,
  data: oneOfType([string, number]),
  children: node,
};

function getCurrencyTransferDetails(account) {
  return account.map(({ fieldName, value }) => {
    return (
      <CopyRow key={fieldName} title={fieldName} text={value} truncate={fieldName.length > 30} />
    );
  });
}

const Details = () => {
  const [index, onChange] = useState(0); // eslint-disable-line no-unused-vars
  const { setShowModal } = useContext(PaymentContext);
  const { invoices, numberOfInvoices } = useSelectedTotal();
  const bankDetails = useBankDetails();
  const hasBankDetails = Array.isArray(bankDetails) && bankDetails.length > 0;

  if (!invoices || !invoices.length || !hasBankDetails) return null;

  const invoiceRefs = invoices.map(i => i.InvoiceNumber).join(', ');
  const tabbingLimitExceeded = bankDetails.length > 3;

  const tabs = bankDetails.map(({ fields, currency }) => {
    return {
      tab: currency,
      panel: (
        <TabPanel>
          <Stack dividers>
            {getCurrencyTransferDetails(fields)}
            <CopyRow title="Invoices" data={invoiceRefs}>
              <Tooltip position={Position.RIGHT} content={invoiceRefs}>
                <Token>{pluralize('invoice', numberOfInvoices, true)}</Token>
              </Tooltip>
            </CopyRow>
          </Stack>
        </TabPanel>
      ),
    };
  });

  function handleChange(newIndex) {
    onChange(Number(newIndex));
  }

  return (
    <Stack gap="medium">
      <Tabs onChange={handleChange} index={index}>
        <Stack>
          {tabbingLimitExceeded ? (
            <Select onChange={e => handleChange(e.target.value)}>
              {tabs.map(({ tab }, i) => (
                <option key={i} value={i}>
                  {tab}
                </option>
              ))}
            </Select>
          ) : (
            <TabList spacing="centered" accentColor="Blue" size="small">
              {tabs.map(({ tab }, i) => (
                <Tab index={i} key={i}>
                  {tab}
                </Tab>
              ))}
            </TabList>
          )}
          <TabPanels>{tabs.map(({ panel }, i) => React.cloneElement(panel, { key: i }))}</TabPanels>
        </Stack>
      </Tabs>
      <Button width="100%" onClick={() => setShowModal(false)}>
        Back
      </Button>
    </Stack>
  );
};

export default Details;
