import React, { useContext } from 'react';
import CheckoutForm from '../../../../../../../components/PaymentProviders/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../../../../../config/index';
import getPaymentIntent from '../../../../../../../services/getPaymentIntent';
import useContact from '../../../../../../../hooks/useContact';
import { PaymentContext } from '../../../../../../../providers/paymentContext';
import usePush from '../../../../../../../hooks/usePush';
import { useOnMount } from 'chaser-components';
import useSelectedTotal from '../../../../../../../hooks/useSelectedTotal';
import useCardDetails from '../../../../../../../hooks/useCardDetails';
import LoadingMessage from '../../../../../../../components/LoadingMessage';
import elementAppearance from '../../../../../../../components/PaymentProviders/elementAppearance';
import { paymentProviders, ddMandateMapping } from '../../../../../../../util/constants';

const ByDebit = () => {
  const { setShowModal, setPaymentError } = useContext(PaymentContext);
  const { invoices, currency, amount } = useSelectedTotal(true);
  const { orgId, contactId } = useContact();
  const cardDetails = useCardDetails(paymentProviders.stripe);

  const { response: clientSecret, execute, loading } = usePush(async () => {
    const invoicesMapped = invoices.map(i => ({
      id: i._id,
      amount: i.isPartial ? i.instalmentsTotal : i.AmountDue,
    }));

    if (!invoices || invoices.length === 0) return;

    const result = await getPaymentIntent({
      orgId,
      contactId,
      currency,
      invoices: invoicesMapped,
      paymentType:
        ddMandateMapping[currency.toLowerCase()] &&
        ddMandateMapping[currency.toLowerCase()].stripeDDMethod,
    });
    return result.data.client_secret;
  });

  async function doIt() {
    const [err] = await execute();

    if (err) {
      const message =
        err.message?.includes(400) && amount > 10000
          ? 'Direct Debit is only supported for payments of $10,000 or less. Please remove some invoices and try again.'
          : 'There was an issue processing your payment.';
      setPaymentError({
        message,
      });
    }
  }

  useOnMount(() => {
    doIt();
  });

  const stripePromise = loadStripe(config.stripeKey, {
    stripeAccount: cardDetails.accountId,
  });

  if (loading) return <LoadingMessage />;

  const options = {
    appearance: elementAppearance,
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        onCancel={() => setShowModal(false)}
        isLoading={loading}
        setPaymentError={setPaymentError}
        isDebit={true}
      />
    </Elements>
  );
};

export default ByDebit;
