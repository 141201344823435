import React from 'react';
import toFinite from 'lodash/toFinite';
import {
  paymentTypes,
  paymentProviders,
  unipaasSupportedCurrencies,
  accountIntegrationSystemsSupported,
  multiOptionalTypeProviders,
} from '../util/constants';

const ellipsis = (str, len) => {
  return str && str.length > len ? (
    <span>
      {str.substring(0, len)}
      ...
    </span>
  ) : (
    str
  );
};

const toCurrency = (amount, currencyCode, style = 'currency') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style,
    currency: currencyCode,
    minimumFractionDigits: Math.floor(amount) === amount ? 0 : 2,
    maximumFractionDigits: 2,
  });

  return amount === 0 || parseFloat(amount) === 0 || toFinite(amount)
    ? formatter.format(amount)
    : '';
};

function convertCurrency(amount, fromCode, toCode, rates) {
  if (!rates[fromCode]) throw new Error(`Currency ${fromCode} not supported`);
  if (!rates[toCode]) throw new Error(`Currency ${toCode} not supported`);

  return amount * (rates[toCode] / rates[fromCode]);
}

function hasDeprecatedAccount(baseCurrency, paymentProvider, invoices) {
  // accountCode is deprecated
  return (
    paymentProvider &&
    !!paymentProvider.accountCode &&
    !paymentProvider.currencies &&
    invoices.every(i => i.CurrencyCode === baseCurrency)
  );
}

const enabledPaymentsHandler = {
  [paymentProviders.stripe]: ({ paymentProvider, accountWithoutRestrictions }) => {
    if (accountWithoutRestrictions) return true;

    const currenciesExistWithTypeCard = paymentProvider.currencies.filter(
      c => !c.type || c.type === paymentTypes.card,
    );

    return currenciesExistWithTypeCard.length > 0;
  },
  [paymentProviders.unipaas]: ({ paymentProvider, selectedInvoiceCurrency }) => {
    if (!paymentProvider && !paymentProvider.enabled) {
      return false;
    }

    return unipaasSupportedCurrencies.includes(selectedInvoiceCurrency.toUpperCase());
  },
};

function paymentEnabled(paymentProvider, invoices, selectedInvoiceCurrency, accountingSoftware) {
  const availableCurrencies = !!(paymentProvider && paymentProvider.currencies)
    ? paymentProvider.currencies.map(c => c.currency)
    : [];

  const isEnabledType = multiOptionalTypeProviders.includes(
    paymentProvider && paymentProvider.provider,
  )
    ? paymentProvider.enabledTypes?.includes(paymentTypes.card)
    : true;

  const hasAvailableAccount =
    availableCurrencies.length > 0 &&
    invoices.some(i => availableCurrencies.includes(i.CurrencyCode));

  const handler = enabledPaymentsHandler[paymentProvider?.provider?.toUpperCase()];

  if (!handler) {
    return false;
  }

  if (accountIntegrationSystemsSupported.includes(accountingSoftware?.toUpperCase())) {
    return (
      hasAvailableAccount &&
      isEnabledType &&
      handler({ paymentProvider, accountWithoutRestrictions: false, selectedInvoiceCurrency })
    );
  }

  return (
    isEnabledType &&
    handler({ paymentProvider, accountWithoutRestrictions: true, selectedInvoiceCurrency })
  );
}

function showButtonForPendingDebit(invoice) {
  return (
    invoice.Status === 'AUTHORISED' &&
    invoice.paymentPending?.isPaymentPending &&
    invoice.paymentPending?.provider.toUpperCase() === paymentProviders.stripe
  );
}

function calculateTotal(invoices, rates, baseCurrency) {
  let totalAmountCents = 0;
  let currency = baseCurrency;
  if (new Set(invoices.map(i => i.CurrencyCode)).size === 1) {
    currency = invoices[0].CurrencyCode ?? baseCurrency;
  }

  for (const inv of invoices) {
    const amountCents = (inv.isPartial ? inv.instalmentsTotal : inv.AmountDue) * 100;
    const invCurrency = inv.CurrencyCode || baseCurrency;
    const convertedAmountCents = convertCurrency(amountCents, invCurrency, currency, rates);
    totalAmountCents += convertedAmountCents;
  }

  totalAmountCents = Math.ceil(totalAmountCents);

  return [totalAmountCents / 100, currency];
}

const stateFeesText = (orgPaymentData, paymentType, currency) => {
  const feeData = orgPaymentData?.fees?.find(fee => fee.type === paymentType);
  let prefix = '';

  if (!feeData) {
    return false;
  }

  if (feeData.type === paymentTypes.card) {
    prefix = 'This includes ';
  }

  if (
    [
      paymentTypes.bacs_debit,
      paymentTypes.sepa_debit,
      paymentTypes.us_bank_account,
      paymentTypes.au_becs_debit,
    ].includes(feeData.type)
  ) {
    prefix = 'All direct debit charges will include ';
  }

  if (+feeData.fixedFee && +feeData.variablePercentageFee) {
    return `${prefix} ${feeData.variablePercentageFee}% + ${toCurrency(
      +feeData.fixedFee,
      currency,
    )} transaction fee`;
  }

  if (+feeData.fixedFee) {
    return `${prefix} a ${toCurrency(+feeData.fixedFee, currency)} transaction fee`;
  }

  if (+feeData.variablePercentageFee) {
    return `${prefix} ${+feeData.variablePercentageFee}% transaction fee`;
  }

  return false;
};

export {
  toCurrency,
  ellipsis,
  calculateTotal,
  convertCurrency,
  paymentEnabled,
  hasDeprecatedAccount,
  enabledPaymentsHandler,
  showButtonForPendingDebit,
  stateFeesText,
};
