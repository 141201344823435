import { convertCurrency } from '../util';
import { paymentProviders } from '../util/constants';
import useCardDetails from './useCardDetails';
import useRates from './useRates';

const convertOrgCurrencyInPaymentCurrency = (fee, orgCurrency, paymentCurrency, rates) => {
  if (orgCurrency === paymentCurrency) {
    return fee;
  }

  return convertCurrency(fee, orgCurrency, paymentCurrency, rates);
};

const summaryFeesInfo = (
  orgPaymentData,
  paymentType,
  orgCurrency,
  paymentCurrency,
  amount,
  rates,
) => {
  let valuesWithFees = {};
  const feeData = orgPaymentData?.fees?.find(fee => fee.type === paymentType);

  if (!feeData || amount === 0) {
    return valuesWithFees;
  }

  const fixedFee =
    +convertOrgCurrencyInPaymentCurrency(
      +feeData.fixedFee,
      orgCurrency,
      paymentCurrency,
      rates,
    ).toFixed(2) || 0;
  const variablePercentageFee = +feeData.variablePercentageFee || 0;

  valuesWithFees = {
    fixedFee: fixedFee,
    amountWithFixedFee: fixedFee + amount,
  };

  valuesWithFees = {
    ...valuesWithFees,
    variablePercentageFee: variablePercentageFee,
    amountOfvariablePercentageFee: +((variablePercentageFee / 100) * amount).toFixed(2),
    amountWithVariablePercentageFee: +((variablePercentageFee / 100) * amount + amount).toFixed(2),
  };

  return valuesWithFees;
};

function useTotalWithOrWithoutFees(paymentType, amount, orgCurrency, paymentCurrency) {
  const rates = useRates();
  const cardDetails = useCardDetails(paymentProviders.stripe);

  const summaryFInfo = summaryFeesInfo(
    cardDetails,
    paymentType,
    orgCurrency,
    paymentCurrency,
    amount,
    rates,
  );
  return summaryFInfo && (summaryFInfo.fixedFee || summaryFInfo.variablePercentageFee)
    ? summaryFInfo.fixedFee + summaryFInfo.amountWithVariablePercentageFee
    : amount;
}

export { summaryFeesInfo };
export default useTotalWithOrWithoutFees;
